import axiosN from "axios";
export const historyTrade = {
  data() {
    return {
      historyTradeList: [],
      totalHands: 0, //平仓总手数
      totalProfit: 0, //总盈亏
      totalIn: 0, //总收入
      totalOut: 0, //总支出
      pageSize: 15,
      page: 0,
      pageCount: 0,
    };
  },
  methods: {
    //获取历史交易 盈亏数据
    handleGetData(params) {
      if (this.page != 1 && this.page > this.pageCount) {
        this.$toast("已显示全部");
        return;
      }
      let url = "/tradeKline"; //真实账户
      if (this.$store.state.login.userType * 1 != 1) {
        //模拟账户  1真实 0模拟
        url = "/demoKline";
      }
      /**
       * params 参数
       * s	必须	string	开始时间，如：2019-12-11 00:00:00
       * e	必须	string	结束时间，如：2019-12-18 11:27:27
       * c	非必须	int	交易类型（默认不传参数为所有记录不区分订单类型，0:平仓记录，1：入金记录，2：出金记录，3：入金+出金记录，4：排除入金、出金外的其它记录，5：所有出入金记录（包含赠金、出金、 入金、首入赠金、赠金扣回等其它），6=信用金 CREDIT，7：查詢 OP_BUY，OP_SELL，OP_BALANCE，返回資金流水數據，100=仅包含劵交易）
       * t	非必须	bool	不包含 “卡劵交易”，true:不包含,false：包含，默认false，，仅当交易类型为平仓记录（参数c=0）时有效
       * pi	必须	int	当前页数,第一页为1
       * ps	必须	int	每页条数
       **/
      axiosN({
        url: url + "/TradeInfo/TradeListRequest",
        method: "POST",
        data: params,
        headers: {
          token: this.$store.state.socket.wsLoginInfo.t,
          login: this.$store.state.login.userInfo
            ? this.$store.state.login.userInfo.account
            : "",
        },
      })
        .then((response) => {
          //console.log(response);
          let res = response.data;
          if (res.IsSuccess) {
            this.loading = false;
            if (res.TotalCount) {
              if (this.page == 1) {
                this.historyTradeList = res.Data;
              } else {
                this.historyTradeList.push(...res.Data);
              }
              this.pageCount = res.PageCount;
              if (params.c === 5) {
                this.totalIn = res.in; //总收入
                this.totalOut = res.out; //总支出
              } else {
                this.totalHands = res.tsl; //平仓总手数
                this.totalProfit = res.tst; //订单总盈亏（ 平仓盈亏+隔夜仓息+手续费）
              }
            } else {
              this.historyTradeList = [];
              this.pageCount = 0;
              if (params.c === 5) {
                this.totalIn = 0; //总收入
                this.totalOut = 0; //总支出
              } else {
                this.totalHands = 0; //平仓总手数
                this.totalProfit = 0; //订单总盈亏（ 平仓盈亏+隔夜仓息+手续费）
              }
            }
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast("请重试");
        });
    },
  },
};
