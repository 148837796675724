<template>
  <div class="trade-page history-trade">
    <h1 class="h1">历史盈亏</h1>
    <Tab
      ref="fundsTab"
      :tabList="tabList"
      @changeTab="handleChangeTab"
      :tabIndex="tabIndex"
    />
    <div class="search" v-show="isShowSearch">
      <el-date-picker
        v-if="!isWap"
        v-model="searchDate"
        type="daterange"
        align="right"
        unlink-panels
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
        value-format="yyyy-MM-dd"
      />
      <div v-else class="search-input">
        <input
          class="input"
          type="text"
          placeholder="起始日期"
          v-model="searchDate[0]"
          @click="datetimeInput('s')"
        />
        <span class="search-line">-</span>
        <input
          class="input"
          type="text"
          placeholder="结束日期"
          v-model="searchDate[1]"
          @click="datetimeInput('e')"
        />
      </div>
      <button class="button btn-search" @click="handleSearch">查询</button>
    </div>
    <van-list
      class="trade-list history-trade-list"
      v-model="loading"
      :offset="15"
      :finished="finished"
      finished-text="暂无更多..."
      @load="infinite"
    >
      <div
        class="trade-item"
        v-for="item in historyTradeList"
        :key="item.i"
        @click="handleInfo(item)"
      >
        <div class="item-info" v-if="item.s">
          <div class="item-name-price">
            <p class="item-name">
              {{ allSymbol[item.s] ? allSymbol[item.s] : item.s }}
              <span :class="item.c === 0 ? 'buy' : 'sale'"
                >{{ item.c === 0 ? "买入" : "卖出"
                }}{{ (item.v / 100).toFixed(2) }}</span
              >
            </p>
            <p class="date">{{ item.j ? item.j.split(".")[0] : "" }}</p>
            <div class="item-price">
              <p class="open-price">开仓价：{{ item.f.toFixed(item.d) }}</p>
              <p class="open-price">平仓价：{{ item.u.toFixed(item.d) }}</p>
            </div>
          </div>
          <p
            class="profit"
            :class="item.p > 0 ? 'up' : item.p === 0 ? '' : 'down'"
          >
            {{ item.p }}
          </p>
        </div>
      </div>
    </van-list>
    <van-popup v-model="searchTimeShow" position="bottom" :overlay="true">
      <van-datetime-picker
        v-model="datetimepickerDate"
        :max-date="maxDate"
        type="date"
        @confirm="searchSave"
        @cancel="searchCancel"
      />
    </van-popup>
    <div class="total-info">
      <p class="total-p">
        <span class="total-name">总盈亏</span>
        <span class="total-num">{{ totalProfit }}</span>
      </p>
      <p class="total-p">
        <span class="total-name">总手数</span>
        <span class="total-num hands">{{ totalHands }}</span>
      </p>
    </div>
    <!-- 平仓详情 -->
    <Dialog
      ref="succ"
      :dialogObj="dialogObj"
      @confirm="handleConfirm"
      @cancel="handleCancel"
    >
      <div class="dialog-detail">
        <p class="dialog-p">
          <span class="p-title">盈亏</span>
          <span
            class="p-val"
            :class="orderInfo.p > 0 ? 'up' : orderInfo.p == 0 ? '' : 'down'"
            >{{ orderInfo.p }}</span
          >
        </p>
        <p class="dialog-p">
          <span class="p-title">订单号</span>
          <span class="p-val">{{ orderInfo.i }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">产品</span>
          <span class="p-val">{{
            allSymbol[orderInfo.s] ? allSymbol[orderInfo.s] : orderInfo.s
          }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">开仓方向</span>
          <span class="p-val">{{ orderInfo.c === 0 ? "买入" : "卖出" }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">开仓价格</span>
          <span class="p-val">{{ orderInfo.f }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">平仓价格</span>
          <span class="p-val">{{ orderInfo.u }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">交易手数</span>
          <span class="p-val">{{ orderInfo.v / 100 }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">止盈价格</span>
          <span class="p-val">{{ orderInfo.g }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">止损价格</span>
          <span class="p-val">{{ orderInfo.h }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">税费</span>
          <span class="p-val">{{ orderInfo.t ? orderInfo.t : "--" }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">库存费</span>
          <span class="p-val">{{ orderInfo.q ? orderInfo.q : "--" }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">手续费</span>
          <span class="p-val">{{ orderInfo.k ? orderInfo.k : "--" }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">开仓时间</span>
          <span class="p-val">{{
            orderInfo.b ? orderInfo.b.split(".")[0] : ""
          }}</span>
        </p>
        <p class="dialog-p">
          <span class="p-title">平仓时间</span>
          <span class="p-val">{{
            orderInfo.j ? orderInfo.j.split(".")[0] : ""
          }}</span>
        </p>
        <!-- <p class="dialog-p">
          <span class="p-title">备注</span>
          <span class="p-val"></span>
        </p> -->
      </div>
    </Dialog>
  </div>
</template>

<script>
import Tab from "@/components/tab.vue";
import Dialog from "@/components/dialog.vue";
import { formatDate } from "@/utils/tool.js";
import { historyTrade } from "@/mixins/historyTrade.js";
import symbols from "@/config/symbols.js";
export default {
  name: "historyTrade",
  components: {
    Tab,
    Dialog,
  },
  mixins: [historyTrade],
  data() {
    return {
      tabList: [
        {
          label: "当日",
          value: "1",
        },
        {
          label: "最近一周",
          value: "2",
        },
        {
          label: "最近一月",
          value: "3",
        },
        {
          label: "更多",
          value: "4",
        },
      ],
      searchDate: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      tabIndex: 1, //选项卡默认值
      searchTimeShow: false,
      loading: false,
      finished: false,
      datetimepicker: "s",
      datetimepickerDate: "",
      openId: "", //点击的信息id
      maxDate: new Date(),
      isShowSearch: false,
      dialogObj: {},
      orderInfo: {}, //选择的订单详情
    };
  },
  computed: {
    isWap() {
      return this.$store.state.common.isWap;
    },
  },
  methods: {
    // 搜索
    handleSearch() {
      if (!this.searchDate || this.searchDate.length < 2) {
        this.$toast("请输入完整时间段");
        return;
      }

      let s = this.searchDate[0];
      let e = this.searchDate[1];
      let sD = new Date(s).getTime();
      let sE = new Date(e).getTime();
      let sF = (sE - sD) / (1000 * 60 * 60 * 24); //结束时间减去开始时间
      if (sF > 30) {
        this.$toast("时间间隔不能大于30天");
        return;
      }
      if (sF < 0) {
        this.$toast("结束时间不能小于开始时间");
        return;
      }

      this.page = 0;
      this.loading = false;
      this.finished = false;
      this.infinite();
    },
    // 无限加载数据
    infinite() {
      this.loading = true;
      if (this.page > 0 && this.page + 1 > this.pageCount) {
        this.finished = true;
        return;
      }
      this.page += 1;
      let params = this.handleParams();
      this.handleGetData(params);
    },
    // 切换顶部出入金
    handleChangeTab(index) {
      this.tabIndex = index;
      if (this.tabIndex != 4) {
        this.isShowSearch = false;
        let date = new Date();
        let nowDate = formatDate(date).split(" ")[0];
        if (this.tabIndex == 1) {
          //当日
          this.$set(this.searchDate, 0, nowDate);
          this.$set(this.searchDate, 1, nowDate);
          // this.searchDate[0] = nowDate;
          // this.searchDate[1] = nowDate;
        } else if (this.tabIndex == 2) {
          //最近一周
          // this.searchDate[0] = formatDate(date - 7 * 60 * 60 * 24 * 1000).split(
          //   " "
          // )[0];
          // this.searchDate[1] = nowDate;
          this.$set(
            this.searchDate,
            0,
            formatDate(date - 7 * 60 * 60 * 24 * 1000).split(" ")[0]
          );
          this.$set(this.searchDate, 1, nowDate);
        } else if (this.tabIndex == 3) {
          //最近一月
          // this.searchDate[0] = formatDate(
          //   date - 30 * 60 * 60 * 24 * 1000
          // ).split(" ")[0];
          // this.searchDate[1] = nowDate;
          this.$set(
            this.searchDate,
            0,
            formatDate(date - 30 * 60 * 60 * 24 * 1000).split(" ")[0]
          );
          this.$set(this.searchDate, 1, nowDate);
        }
      } else {
        this.isShowSearch = true;
      }
      this.page = 0;
      this.loading = false;
      this.finished = false;
      this.infinite();
    },
    // 查询数据
    // getData() {
    //   //获取历史交易记录 默认当天
    //   let params = {
    //     startTime: this.searchDate[0], //开始时间  格式：2021-01-21
    //     endTime: this.searchDate[1], //结束时间  格式：2021-01-22
    //   };
    //   this.$store.dispatch("socket/handleHistoryTradeList", params);
    //   this.finished = true;
    // },
    //时间面板取消
    searchCancel() {
      this.searchTimeShow = false;
    },
    // 搜索时间设置
    searchSave(v) {
      let date = formatDate(v);
      switch (this.datetimepicker) {
        case "s":
          //this.searchDate[0] = date.split(" ")[0];
          this.$set(this.searchDate, 0, date.split(" ")[0]);
          break;
        case "e":
          //this.searchDate[1] = date.split(" ")[0];
          this.$set(this.searchDate, 1, date.split(" ")[0]);
          break;
      }
      this.searchTimeShow = false;
    },
    handleInfo(item) {
      this.orderInfo = item;
      console.log(item);
      this.dialogObj = {
        isShow: true,
        title: "平仓详情",
        confirmText: "确定",
      };
    },
    handleConfirm() {
      this.dialogObj = {
        isShow: false,
      };
    },
    handleCancel() {},
    datetimeInput(type) {
      //点击input时间面板赋值
      this.datetimepicker = type;
      this.datetimepickerDate = new Date();
      this.searchTimeShow = true;
    },
    handleParams() {
      return {
        s: this.searchDate[0] + " 00:00:00", //开始时间  格式：2021-01-21 00:00:00
        e: this.searchDate[1] + " 23:59:59", //结束时间  格式：2021-01-22 00:00:00
        c: 0,
        pi: this.page,
        ps: this.pageSize,
      };
    },
  },
  created() {
    this.handleChangeTab(1);
    this.allSymbol = {
      ...symbols.foreignSymbolsList,
      ...symbols.goodsSymbolsList,
      ...symbols.indexSymbolsList,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/scss/trade.scss";
</style>
